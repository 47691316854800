import React, { FC } from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'

import { PlainTemplate } from '../../components/layout/PlainTemplate'

const TermsOfService: FC<{ data: any }> = ({ data }) => {
  const content = data.contentfulPage.content.childMarkdownRemark.html

  return (
    <PlainTemplate>
      <div tw="py-12 bg-white lg:mt-8">
        <div tw="max-w-screen-md mx-auto px-4 sm:px-6 lg:px-8">
          <h1 tw="text-2xl font-semibold text-center">
          Terms of Service
          </h1>

          <div tw="mt-8">
            <article tw="prose-sm" dangerouslySetInnerHTML={{ __html: content }}/>
          </div>
        </div>
      </div>
    </PlainTemplate>
  )
}

export const query = graphql`
  query tos {
    contentfulPage(
      slug: { eq: "terms-of-service-lawhive" }
    ) {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default TermsOfService
